import React from "react";
import { Grid, Typography } from "@mui/material";
import { WebGLFluidAnimation } from "../../webgl-fluid-animation/WebGLFluidAnimation";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import Scroll from "../../webgl-fluid-animation/Scroll";

export const Intro = ({ title, subtitle, containerStylesOverride }: { title: string; subtitle: string, containerStylesOverride?: Object }) => {
    const theme = useTheme() as any;
    const xsDown = useMediaQuery(theme.breakpoints.down("xs"));

    const handleClick = (event: any) => {
        if (event.detail === 2) {
            Scroll.to('#statement');
        }
    };

    return (
        <Grid container onClick={handleClick} style={{ backgroundColor: "white", position: "relative", minHeight: "100vh", ...containerStylesOverride }}>
            {process.env.REACT_APP_SHOW_WEBGL_ANIMATION !== "false" && <WebGLFluidAnimation />}
            <Grid
                item
                container
                xs={12}
                sm={7}
                direction="column"
                justifyContent={xsDown ? "flex-start" : "center"}
                alignItems="center"
                style={{ padding: "10%", zIndex: 4, pointerEvents: "none" }}
                spacing={4}
            >
                <Grid item style={{ width: "100%" }}>
                    <Typography variant="h1" color="primary.contrastText" align="left">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h2" color="white" align="left">
                        {subtitle}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
