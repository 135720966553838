import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

export interface ITestimonialConfig {
    text: string;
    image: ITestimonialImage;
    textColor: string;
    backgroundColor: string;
}

export interface ITestimonialImage {
    src: string;
    alt: string;
    style?: Object;
}

export const TestimonialCard = ({
    testimonialConfig,
}: {
    testimonialConfig: ITestimonialConfig;
}) => {
    const theme = useTheme() as any;
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));

    const { text, image, textColor, backgroundColor } = testimonialConfig;
    const { alt, src, style } = image;
    return (
        <Grid
            item
            container
            style={{
                padding: "1.9em 3em",
                background: backgroundColor,
                color: "#fff",
                borderRadius: "4em",
                transition: "color .2s ease,box-shadow .2s ease",
            }}
            alignItems={"center"}
            direction="row"
            spacing={smDown ? 2 : 0}
        >
            <Grid item container sm={3} justifyContent="center" alignItems="center">
                <Grid item>
                    <img src={src} height="30px" alt={alt} style={style} />
                </Grid>
            </Grid>
            <Grid item container justifyContent="flex-start" alignItems="center" sm={9}>
                <Grid item>
                    <Typography variant={smDown ? "body2" : "body1"} align="right" color={textColor}>
                        {text}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
