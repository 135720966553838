import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid, useTheme } from '@mui/material';
import { IImage } from '../../../interfaces';
import { useMediaQuery } from "@mui/material";
import { useOpenPage } from '../../../../../services/tools';

export const OurSolutionsCard = ({ title, subtitleIcons, textes, url }: { title?: string | JSX.Element, subtitleIcons?: IImage[], textes?: string[], url: string }) => {
    const theme = useTheme() as any;
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    const openPage = useOpenPage();

    return (
        <Card sx={{ maxWidth: "100%", height: smDown ? "850px" : "700px", backgroundColor: theme.palette.text.primary, borderRadius: "2rem" }}
            onClick={() => openPage(url)}
        >
            <CardContent>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                        <Typography gutterBottom variant="h1" color="primary.contrastText">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item container spacing={2} style={{ padding: "20px" }}>
                        {textes?.map(text => <Grid item><Typography variant="body1" style={{ color: "white" }}>
                            {text}
                        </Typography></Grid>)}
                    </Grid>
                    <Grid item container direction="row" justifyContent="space-evenly">
                        {subtitleIcons?.map(({ url, title, style }) => <IconWithTitle url={url} style={style} title={title} />)}
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    );
}

const IconWithTitle = ({ url, title, style }: { url: string, title: string, style?: Object }) => {
    return <Grid item container xs={4} direction="column">
        <Grid item>
            <img src={url} width="30%" style={style} alt={title || "test-alt"} />
        </Grid>
        <Grid>
            <Typography variant="body2" style={{ color: "white", textTransform: "uppercase" }}>{title}</Typography>
        </Grid>
    </Grid>;

}