import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { IOurTeamConfig, OurTeam } from "../../libs/ui-components/home-page-blocks/our-team/OurTeam";
import { useTheme } from "@mui/material";
import { useTranslations } from "../../services/i18n";

interface IAboutPageTextElement {
    text: string;
    variant: any;
    style?: Object;
}

const useOurTeamConfig = () => {
    const t = useTranslations();
    const theme = useTheme() as any;
    const ourTeamConfig: IOurTeamConfig = {
        title: t('about.our-team.title'),
        elements: [
            {
                name: "Alexis Gléron",
                description:
                    t('about.our-team.description.alexis'),
                image: {
                    src: "https://fr.linkedin.com/in/alexis-gl%C3%A9ron-0b6bb576",
                    alt: "test-alt",
                },
                textColor: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.contrastText,
            },
            {
                name: "Eric Pieuchot",
                description:
                    t('about.our-team.description.eric'),
                image: {
                    src: "https://uk.linkedin.com/in/eric-pieuchot",
                    alt: "test-alt",
                },
                textColor: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.contrastText,
            },
            {
                name: "Christophe Bagot",
                description:
                    t('about.our-team.description.christophe'),
                image: {
                    src: "https://fr.linkedin.com/in/cbagot",
                    alt: "test-alt",
                },
                textColor: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.contrastText,
            },
        ],
    };
    return ourTeamConfig;
}

const useGetAboutPageConfig = () => {
    const t = useTranslations();
    const texts: IAboutPageTextElement[] = [
        {
            variant: "body1",
            text: t('about.our-vision.texts.first'),
        },

        {
            variant: "body1",
            text: t('about.our-vision.texts.second'),
        },

        {
            variant: "body1",
            text: t('about.our-vision.texts.third'),
            style: { fontWeight: "bold" },
        },

        {
            variant: "body1",
            text: t('about.our-vision.texts.fourth'),
        },
        {
            variant: "body1",
            text: t('about.our-vision.texts.fifth'),
        },
    ];
    const title = t('about.our-vision.title');
    return {
        title,
        elements: texts
    };
}

export const AboutPage = () => {
    const theme = useTheme() as any;
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    const ourTeamConfig = useOurTeamConfig();
    const { title, elements: texts } = useGetAboutPageConfig();

    return smDown ? (
        <Grid container justifyContent="center" spacing={3} style={{ padding: `${theme.spacing(10)} ${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)}` }}>
            <AugmentedEnergyLogo />
            <AboutPageTextContainer title={title} texts={texts} />
            <Grid item xs={12}>
                <OurTeam ourTeamConfig={ourTeamConfig} />
            </Grid>
        </Grid >
    ) : (
        <Box
            style={{
                background: "linear-gradient(180deg, white 30%,white 40%,#e9e9e9 45%,#e9e9e9)",
                padding: "10%",
            }}
            pt={10}
        >
            <Grid container justifyContent="center" spacing={5}>
                <AugmentedEnergyLogo />
                <AboutPageTextContainer title={title} texts={texts} />
                <Grid item xs={12} style={{ marginTop: theme.spacing(6) }}>
                    <OurTeam ourTeamConfig={ourTeamConfig} />
                </Grid>
            </Grid>
        </Box >
    );
};

const AugmentedEnergyLogo = () => {
    const theme = useTheme() as any;
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));

    return <Grid item>
        {smDown ? (
            <img src="/logo_AE-VF.png" alt="Logo Augmented Energy" height="80px" />
        ) : (<Box p={4}>
            <img src="/logo_AE-VF.png" alt="Logo Augmented Energy" height="80px" />
        </Box>)
        }
    </Grid >
}

const AboutPageText = ({ texts }: { texts: IAboutPageTextElement[] }) => {
    return <Grid
        item
        xs={12}
        container
        justifyContent="center"
        spacing={4}
    >
        {texts.map((text) => (
            <Grid xs={12} item>
                <Typography variant={text.variant} align="left" style={text.style || {}}>
                    {text.text}
                </Typography>
            </Grid>
        ))}
    </Grid>
}

const AboutPageTextContainer = ({ title, texts }: { title: string, texts: IAboutPageTextElement[] }) => {
    return (
        <Grid item container direction="row" spacing={8}>
            <Grid item>
                <Typography variant="h1" color="text.primary" align="left">
                    {title}
                </Typography>
            </Grid>
            <Grid item>
                <AboutPageText texts={texts} />
            </Grid>
        </Grid>
    );
}