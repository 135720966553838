import { Grid, Typography } from "@mui/material";
import React from "react";
import { ITeamCardConfig, TeamCard } from "./components/TeamCard";

export interface IOurTeamConfig {
    title: string;
    elements: ITeamCardConfig[];
}

export const OurTeam = ({ ourTeamConfig }: { ourTeamConfig: IOurTeamConfig }) => {
    const { title, elements } = ourTeamConfig;
    return (
        <Grid item container>
            <Grid item container direction="column" spacing={8}>
                <Grid item>
                    <Typography variant="h1" color="text.primary" align="left">
                        {title}
                    </Typography>
                </Grid>
                <Grid item container direction="column" spacing={10}>
                    {elements.map((element, index) => {
                        return (
                            <Grid
                                item
                                container
                                justifyContent={index % 2 !== 0 ? "flex-start" : "flex-end"}
                            >
                                <Grid item style={{ maxWidth: "100%" }}>
                                    <TeamCard teamCardConfig={element} />
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
};
