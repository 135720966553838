import React from "react";
import { Testimonials as TestimonialsComponent } from "./components/Testimonials";
import { Grid } from "@mui/material";

export const Testimonials = ({
    testimonialsConfig,
    containerStylesOverride,
}: {
    containerStylesOverride?: Object;
    testimonialsConfig?: any;
}) => {
    return <Grid item style={containerStylesOverride}>
        <TestimonialsComponent testimonialsConfig={testimonialsConfig} />
    </Grid>
};
