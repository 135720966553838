import { useHistory } from "react-router";

export const scrollToTop = () => {
    const scrollToTopAnimation = () => {
        const currentPosition = window.pageYOffset;
        if (currentPosition > 0) {
            window.scrollTo(0, currentPosition - 200); // Adjust the scroll speed by changing the second parameter
            requestAnimationFrame(scrollToTopAnimation);
        }
    };

    scrollToTopAnimation();
};

export const useOpenPage = () => {
    const history = useHistory();

    const openPage = (url: string) => {
        if (url.startsWith('http')) {
            window.open(url, '_blank');
        } else {
            history.push(url);
        }
    }

    return openPage;
}