import React from "react";
import { Button, Typography } from "@mui/material";

export const CallToAction = ({ text, onClick }: { text: string, onClick: any }) => {
    return (
        <Button
            variant="contained"
            size="large"
            style={{
                fontWeight: "bold",
                boxShadow: "0 0 0.5em 0.2em #fac505",
                transition: "color .2s ease,box-shadow .2s ease",
                padding: "1.5em 4em",
                borderRadius: "5rem",
                textTransform: "unset",
            }}
            onClick={onClick}
        >
            <Typography variant="h2">{text}</Typography>
        </Button>
    );
};

export default CallToAction;