import { createSlice } from '@reduxjs/toolkit';

const contactUsSlice = createSlice({
    name: 'contactUs',
    initialState: {
        showForm: false
    },
    reducers: {
        setShowContactUsForm(state, action) {
            state.showForm = action.payload;
        },
    }
})

export const { setShowContactUsForm } = contactUsSlice.actions;
export default contactUsSlice.reducer;