import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { FluidAnimationInit } from './FluidAnimation';

export const WebGLFluidAnimation = () => {
    useEffect(() => {
        FluidAnimationInit();
    }, []);

    return (
        <Grid item style={{ height: "100%", width: "100%", position: "absolute", left: 0 }}>
            <canvas className="fluid-canvas" style={{
                color: 'white',
                height: '100%', // Canvas is will respond to size changes without resetting fluid!
                width: '100%',
                margin: 0,
                zIndex: 1,
            }}></canvas>
        </Grid>
    )
}
