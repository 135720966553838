import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import StyledNumber from "./components/StyledNumber";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import "./NumberedList.css";

interface INumberedListElements {
    title: string;
    subtitle: string;
    number: string;
}

export interface INumberedListConfig {
    title: string;
    elements: INumberedListElements[];
}

export const NumberedList = ({ config }: { config: INumberedListConfig }) => {
    const theme = useTheme() as any;
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));

    const { title, elements } = config;
    return (
        <Grid item container direction="column" spacing={8}>
            <Grid item>
                <Typography variant="h1" style={{ color: "white" }} align="left">
                    {title}
                </Typography>
            </Grid>
            {elements.map((element) => (
                <Grid item container direction="row" spacing={smDown ? 4 : 0}>
                    <Grid item xs={12} sm={3}>
                        <Box width={"15%"}>
                            <StyledNumber text={element.number} />
                        </Box>
                    </Grid>
                    <Grid item container justifyItems="flex-start" xs={12} sm={9} direction="column" spacing={3}>
                        <Grid item>
                            <Typography variant="h1" style={{ color: "white" }} align="left">
                                {element.title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className="numbered-list-subtitle" variant="body1" color="secondary.main" align="left">
                                <p dangerouslySetInnerHTML={{ __html: element.subtitle }} />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};
