import React from "react";
import {
    NumberedList,
} from "../numbered-list/NumberedList";
import { Grid } from "@mui/material";

export const OurAdvantages = ({
    ourAdvantagesConfig,
    containerStylesOverride,
}: {
    containerStylesOverride?: Object;
    ourAdvantagesConfig: any;
}) => {
    return (
        <Grid item xs={12} style={containerStylesOverride}>
            <Grid item container xs={11} style={{ padding: "10%" }}>
                <NumberedList config={ourAdvantagesConfig} />
            </Grid>
        </Grid>
    );
};
