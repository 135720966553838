import { Grid, Typography } from "@mui/material";
import React from "react";
import { ITestimonialConfig, TestimonialCard } from "./TestimonialCard";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

export interface ITestimonialsConfig {
    title: string;
    elements: ITestimonialConfig[];
}

export const Testimonials = ({
    testimonialsConfig,
}: {
    testimonialsConfig: ITestimonialsConfig;
}) => {
    const theme = useTheme() as any;
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));

    const { title, elements } = testimonialsConfig;
    return (
        <Grid item container style={{ padding: "10%" }}>
            <Grid item container direction="column" spacing={8}>
                <Grid item>
                    <Typography variant={smDown ? "h2" : "h1"} component="h1" color="text.primary" align="left">
                        {title}
                    </Typography>
                </Grid>
                <Grid item container direction="column" spacing={6}>
                    {elements.map((element, index) => {
                        return (
                            <Grid
                                item
                                container
                                justifyContent={index % 2 === 0 ? "flex-start" : "flex-end"}
                            >
                                <Grid item style={{ maxWidth: smDown ? "90%" : "80%" }}>
                                    <TestimonialCard testimonialConfig={element} />
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
};
