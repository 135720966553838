import * as React from "react";
import { styled } from "@mui/system";
import { Grid, Typography } from "@mui/material";

const StyledNumberComponent = styled("div")({
    color: "#fac505",
    boxShadow: "0 0 0.5em 0.1em #fac505",
    fontSize: "4.5rem",
    letterSpacing: "-.02em",
    padding: "1.2rem 5.6rem",
    background: "#151515",
    overflow: "hidden",
    borderRadius: "5rem",
    transition: "color .2s ease,box-shadow .2s ease",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
});

export default function StyledNumber({ text }: { text: string }) {
    return <StyledNumberComponent>
        <Grid container justifyContent="center">
            <Grid item>
                <Typography variant="h1" align="center" style={{ width: "100%" }}>
                    {text}
                </Typography>
            </Grid>
        </Grid>
    </StyledNumberComponent>;
}
