import { Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import { IImage } from "../interfaces";
import { LinkedInContactIcon } from "./components/LinkedInContactIcon";

export const Footer = ({
    cguUrl,
    logoImageConfig,
    overrideCguText,
    overrideCguTitle,
    contactEmail,
}: {
    cguUrl?: string;
    logoImageConfig?: IImage;
    overrideCguText?: string;
    overrideCguTitle?: string;
    contactEmail?: string;
}) => {
    const theme = useTheme() as any;
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));

    const linkToCgu = cguUrl || "https://noos-prod-public-assets.s3.amazonaws.com/cookies/fr/augmented_energy.pdf";
    const cguText = overrideCguText || "En continuant votre navigation sur ce site web, vous acceptez ses\nCGU et sa politique concernant les cookies.\n© 2023 Augmented Energy"
    return smDown ? (
        <Grid
            item
            container
            direction="column"
            style={{
                width: "100%",
                padding: "10% 30px 10% 50px",
            }}
        >
            <CguBlock linkToCgu={linkToCgu} cguTitle={overrideCguTitle} cguText={cguText} />
            <Grid
                item
                container
                justifyItems="space-between"
                alignItems="center"
                style={{ width: "auto" }}
                direction="row"
            >
                <Grid item container justifyContent={"flex-start"} alignItems="center" xs={6}>
                    <FooterLogo logoImageConfig={logoImageConfig} />
                </Grid>
                <Grid item container xs={6} justifyContent={"flex-end"}>
                    <EmailContactIcon contactEmail={contactEmail} />
                    <LinkedInContactIcon iconWidth="50rem" />
                </Grid>
            </Grid>
        </Grid >
    ) : (
        <Grid
            item
            container
            direction="row"
            style={{
                width: "100%",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "30px"
            }}
        >
            <Grid item container alignItems="center" xs="auto">
                <FooterLogo logoImageConfig={logoImageConfig} />
            </Grid>
            <Grid item style={{ flexGrow: 1, marginLeft: "40px" }}>
                <CguBlock linkToCgu={linkToCgu} cguTitle={overrideCguTitle} cguText={cguText} />
            </Grid>
            <Grid
                item
                container
                justifyItems="flex-end"
                alignItems="center"
                spacing={3}
                style={{ width: "auto" }}
            >
                <EmailContactIcon contactEmail={contactEmail} />
                <LinkedInContactIcon iconWidth="50rem" />
            </Grid>
        </Grid >
    );
};

const EmailContactIcon = ({ contactEmail }: { contactEmail?: string }) => {
    return <Grid item>
        <a href={`mailto: ${contactEmail || "contact@augmented.energy"}`}>
            <IconButton color="primary">
                <EmailIcon style={{ fontSize: "3rem" }} />
            </IconButton>
        </a>
    </Grid>
}

const CguBlock = ({ linkToCgu, cguTitle, cguText }: { linkToCgu: string, cguTitle?: string, cguText?: string }) => {
    const theme = useTheme() as any;
    const cguTextes = cguText?.split('\n');

    return <Grid item container spacing={1} direction="column" alignItems="flex-start">
        <Grid item>
            <a href={linkToCgu} target="_blank" rel="noreferrer" style={{ textDecorationColor: theme.palette.text.disabled }}>
                <Typography variant="body2" color="text.disabled">{cguTitle || "CGU du site"}</Typography>
            </a>
        </Grid>
        <Grid item>
            {cguTextes?.map(cguText => <Typography variant="body2" align="left" color="text.disabled">
                {cguText}
            </Typography>)}
        </Grid>
    </Grid>
}

const FooterLogo = ({ logoImageConfig }: { logoImageConfig?: IImage }) => {
    return <Grid item>
        <img src={logoImageConfig?.url || "/footer/logo_AE_noir.png"} height="30px" alt={logoImageConfig?.title || "Logo Augmented Energy"} style={logoImageConfig?.style || {}} />
    </Grid>
}