import { Grid } from "@mui/material";
import React from "react";
import { CallToAction } from "./components/CallToAction";

export const CallsToAction = ({
    actionText,
    onClick,
    containerStylesOverride,
}: {
    actionText?: string;
    onClick: any;
    containerStylesOverride?: Object;
}) => {
    return (
        <Grid item style={containerStylesOverride}>
            <Grid item container justifyContent="center" alignItems="center" style={{ padding: "5%" }}>
                <Grid item>
                    <CallToAction text={actionText || "JE VEUX UNE DÉMO"} onClick={onClick} />
                </Grid>
            </Grid>
        </Grid>
    );
};
