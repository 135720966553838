import { Grid } from '@mui/material'
import React from 'react'
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

import Flickity from "react-flickity-component";

import "./flickity.css";
import { OurSolutionsCard } from '../our-solutions/components/OurSolutionsCard';
import { IImage } from '../../interfaces';

export interface ICarouselElementConfig {
    title: string | JSX.Element;
    subtitleIcons: IImage[];
    textes: string[];
    url: string;
}

export const Carousel = ({ carouselConfig }: { carouselConfig: ICarouselElementConfig[] }) => {
    const theme = useTheme() as any;
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    const elements = [0, 1, 2].map(index => { // TODO should depend on carouselConfig length
        const { title, subtitleIcons, textes, url } = carouselConfig[index];
        return <CarouselElement
            title={title}
            subtitleIcons={subtitleIcons}
            textes={textes}
            url={url}
        />
    });
    return (
        <Grid item style={{ width: "100%" }}>
            <Flickity options={{ prevNextButtons: !smDown }} >
                {elements}
            </Flickity>
        </Grid>
    );
}

const CarouselElement = ({ title, subtitleIcons, textes, url }: { title: string | JSX.Element, subtitleIcons: IImage[], textes: string[], url: string }) => {
    const theme = useTheme() as any;
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));

    return <Grid container alignItems="center" justifyContent="center" style={smDown ? {} : { width: "70%" }}>
        <Grid item style={smDown ? {} : { padding: "40px" }}>
            <OurSolutionsCard
                title={title}
                subtitleIcons={subtitleIcons}
                textes={textes}
                url={url}
            />
        </Grid>
    </Grid>
}