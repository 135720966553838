import React from 'react'
import { Carousel, ICarouselElementConfig } from '../carousel/Carousel'
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

export const OurSolutions = ({
    title,
    carouselConfig,
    containerStylesOverride,
}: {
    title: string;
    carouselConfig: ICarouselElementConfig[];
    containerStylesOverride?: Object;
}) => {
    const theme = useTheme() as any;
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Grid
            item
            container
            style={{ backgroundColor: "#fac505", ...containerStylesOverride }}
        >
            <Grid item container direction="column" spacing={4} style={{ padding: smDown ? "3% 10% 15% 10%" : "3% 0% 3% 0%" }}>
                <Grid item>
                    <Typography
                        variant="h1"
                        color="text.primary"
                        style={{ fontSize: "bold", marginLeft: "10%" }}
                        align="left"
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid item container justifyContent="right" alignItems="center">
                    <Carousel carouselConfig={carouselConfig} />
                </Grid>
            </Grid>
        </Grid>
    );
};
