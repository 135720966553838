import React, { Fragment } from 'react';

import { Widget } from '@typeform/embed-react';
import { Box, Modal } from '@mui/material';
import { useTheme } from '@emotion/react';

// https://developer.typeform.com/embed/react/#usage
export const ContactUsForm = ({ typeformId, showForm, setShowContactUsForm }: { typeformId: string, showForm: boolean, setShowContactUsForm: Function }) => {
    const theme = useTheme() as any;

    const handleClose = () => {
        setShowContactUsForm(false);
    };

    const typeformWidget = (
        <Box style={{
            margin: theme.spacing(2),
            padding: theme.spacing(2),
            height: '100%'
        }}>
            <Widget
                id={typeformId}
                onSubmit={handleClose}
                style={{ height: '95%' }}
                hideFooter
                hideHeaders
            />
        </Box>
    );

    return (
        <Fragment>
            <Modal open={showForm} onClose={handleClose}>
                {typeformWidget}
            </Modal>
        </Fragment>
    );
}