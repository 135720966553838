import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Intro } from "../../libs/ui-components/home-page-blocks/intro/Intro";
import { WhoWeAre } from "../../libs/ui-components/home-page-blocks/who-we-are/WhoWeAre";
import { OurAdvantages } from "../../libs/ui-components/home-page-blocks/our-advantages/OurAdvantages";
import { useTheme } from "@emotion/react";
import { Testimonials } from "../../libs/ui-components/home-page-blocks/testimonials/TestimonialsBlock";
import { CallsToAction } from "../../libs/ui-components/home-page-blocks/calls-to-action/CallsToAction";
import { useTranslations } from "../../services/i18n";
import { OurSolutions } from "../../libs/ui-components/home-page-blocks/our-solutions/OurSolutions";
import { useDispatch } from "react-redux";
import { setShowContactUsForm } from "../../services/store/features/contact-us/contactUsSlice";
import { ICarouselElementConfig } from "../../libs/ui-components/home-page-blocks/carousel/Carousel";
import { INumberedListConfig } from "../../libs/ui-components/home-page-blocks/numbered-list/NumberedList";
import { TFunction } from "i18next";

const getCarouselConfig = (t: TFunction<"translation", undefined>) => {
    const carouselConfig = [{
        title: t('home.our-solutions.carousel.couverture.title'),
        subtitleIcons:
            [
                { url: "/home-page/carousel/producteurs.png", title: t('home.our-solutions.carousel.customers.producers') },
                { url: "/home-page/carousel/grands-consommateurs.png", title: t('home.our-solutions.carousel.customers.large-consumers') },
                { url: "/home-page/carousel/fournisseurs.png", title: t('home.our-solutions.carousel.customers.suppliers') },
            ],
        textes:
            [
                t('home.our-solutions.carousel.couverture.textes.first'),
                t('home.our-solutions.carousel.couverture.textes.second'),
            ],
        url: "https://blog.augmented.energy/gestion-de-la-couverture",
    }, {
        title: t('home.our-solutions.carousel.equilibre.title'),
        subtitleIcons: [
            { url: "/home-page/carousel/producteurs.png", title: t('home.our-solutions.carousel.customers.producers') },
            { url: "/home-page/carousel/fournisseurs.png", title: t('home.our-solutions.carousel.customers.suppliers') },
        ],
        textes: [
            t('home.our-solutions.carousel.equilibre.textes.first'),
            t('home.our-solutions.carousel.equilibre.textes.second'),
        ],
        url: "https://blog.augmented.energy/gestion-de-perimetre-dequilibre",
    }, {
        title: t('home.our-solutions.carousel.aggregation.title'),
        subtitleIcons: [
            { url: "/home-page/carousel/producteurs.png", title: t('home.our-solutions.carousel.customers.producers') },
            { url: "/home-page/carousel/storage.png", title: t('home.our-solutions.carousel.customers.storage') },
            { url: "/home-page/carousel/fournisseurs.png", title: t('home.our-solutions.carousel.customers.suppliers') },
        ],
        textes: [
            t('home.our-solutions.carousel.aggregation.textes.first'),
            t('home.our-solutions.carousel.aggregation.textes.second'),
        ],
        url: "https://blog.augmented.energy/agregation",
    }] as ICarouselElementConfig[];
    return carouselConfig;
}

const getOurAdvantagesConfig = (t: TFunction<"translation", undefined>) => {
    const config: INumberedListConfig = {
        title: t('home.our-advantages.title'),
        elements: [
            {
                title: t('home.our-advantages.elements.tech.title'),
                subtitle: t('home.our-advantages.elements.tech.subtitle'),
                number: "1",
            },
            {
                title: t('home.our-advantages.elements.solutions.title'),
                subtitle: t('home.our-advantages.elements.solutions.subtitle'),
                number: "2",
            },
            {
                title: t('home.our-advantages.elements.expertise.title'),
                subtitle: t('home.our-advantages.elements.expertise.subtitle'),
                number: "3",
            },
        ],
    } as INumberedListConfig;
    return config;
}

const useTestimonialsConfig = () => {
    const t = useTranslations();
    const theme = useTheme() as any; // TODO improve typing

    const testimonialsConfig = {
        title: t('home.testimonials.title'),
        elements: [
            {
                text: t('home.testimonials.elementsText.second'),
                image: {
                    src: "/home-page/they-recommend-us/LOGO_NOVA.jpeg",
                    alt: "logo client Novajoule",
                    style: { height: "85px", filter: "grayscale()" },
                },
                backgroundColor: theme.palette.background.paper,
                textColor: theme.palette.text.primary,
            },
            {
                text: t('home.testimonials.elementsText.first'),
                image: {
                    src: "/home-page/they-recommend-us/LOGO_DREAM.png",
                    alt: "logo client Dream Energy",
                    style: { height: "85px", filter: "grayscale()" },
                },
                backgroundColor: theme.palette.primary.main,
                textColor: theme.palette.primary.contrastText,
            },
            {
                text: t('home.testimonials.elementsText.third'),
                image: {
                    src: "/home-page/they-recommend-us/LOGO_URBAN.jpg",
                    alt: "logo client Urban Solar Energy",
                    style: { height: "85px", filter: "grayscale()" },
                },
                backgroundColor: theme.palette.background.paper,
                textColor: theme.palette.text.primary,
            },
        ],
    };

    return testimonialsConfig;
}



export const HomePage = () => {
    const theme = useTheme() as any; // TODO improve typing
    const t = useTranslations();
    const dispatch = useDispatch();
    const testimonialsConfig = useTestimonialsConfig();
    const [carouselConfig, setCarouselConfig] = useState<ICarouselElementConfig[] | null>(null);
    useEffect(() => {
        if (t) {
            const carouselConfig = getCarouselConfig(t);
            setCarouselConfig(carouselConfig);
        }
    }, [t]);
    return (
        <Grid container spacing={1} justifyContent="flex-end">
            <Intro
                title={t('home.intro.title')}
                subtitle={t('home.intro.subtitle')}
                containerStylesOverride={{
                    backgroundColor: "rgb(20, 20, 20)"
                }}
            />
            <WhoWeAre
                logoConfig={{
                    src: "/logo_AE-VF.png",
                    height: "80px",
                    alt: t('home.who-we-are.logoTitle'),
                }}
                title={t('home.who-we-are.title')}
                subtitle={t('home.who-we-are.subtitle')}
                actionText={t('home.who-we-are.actionText')}
                containerStylesOverride={{
                    background: "linear-gradient(180deg,#e9e9e9 50%,#e9e9e9 80%,#fac505 90%,#fac505)"
                }}
            />
            {carouselConfig && <OurSolutions title={t('home.our-solutions.title')} carouselConfig={carouselConfig} />}
            <OurAdvantages ourAdvantagesConfig={getOurAdvantagesConfig(t)} containerStylesOverride={{ backgroundColor: theme.palette.primary.main }} />
            <Testimonials testimonialsConfig={testimonialsConfig} containerStylesOverride={{ backgroundColor: theme.palette.secondary.main }} />
            <CallsToAction actionText={t('home.call-to-action-text')} onClick={() => dispatch(setShowContactUsForm(true))} containerStylesOverride={{ backgroundColor: theme.palette.secondary.main, width: "100%" }} />
        </Grid>
    );
};
