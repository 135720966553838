import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Grid, IconButton, ThemeProvider, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { INavbarMenuItemConfig } from '../Navbar';
import { useOpenPage } from '../../../../services/tools';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export const TemporaryDrawer = ({ menuItemsConfig, overrideTheme }: { menuItemsConfig: INavbarMenuItemConfig[], overrideTheme: any }) => {
    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };


    const anchor = "right";
    return (
        <div>
            <React.Fragment key={anchor}>
                <ThemeProvider theme={overrideTheme}>
                    <Grid container alignItems="center" justifyItems={"center"} alignContent={"center"} justifyContent={"center"}>
                        <Grid item>
                            <IconButton style={{ color: "black" }} onClick={toggleDrawer(anchor, true)}>
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        sx={{ "& .MuiBox-root": { width: "auto" } }}
                    >
                        <MenuItems menuItemsConfig={menuItemsConfig} anchor={anchor} onClose={toggleDrawer} />
                    </Drawer>
                </ThemeProvider>
            </React.Fragment>
        </div >
    );
}


const MenuItems = ({ menuItemsConfig, anchor, onClose }: { menuItemsConfig: any, anchor: Anchor, onClose: any }) => {
    const theme = useTheme() as any;
    const openPage = useOpenPage();
    return <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
        role="presentation"
        onClick={onClose(anchor, false)}
        onKeyDown={onClose(anchor, false)}
        style={{ backgroundColor: "rgb(20, 20, 20)", height: "100%" }}
    >
        <List>
            {menuItemsConfig.map((itemConfig: any) => {
                return (
                    <ListItem key={itemConfig.text} disablePadding>
                        <ListItemButton onClick={!itemConfig.onClick ? () => openPage(itemConfig.url) : itemConfig.onClick}>
                            <ListItemText primary={itemConfig.text} style={{ color: theme.palette.primary.contrastText, display: "flex", justifyContent: "flex-end", ...{ ...itemConfig?.style || {} } }} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    </Box>
};