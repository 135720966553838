import { useTheme } from "@emotion/react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { LinkedInContactIcon } from "../../../footer/components/LinkedInContactIcon";

export interface ITeamCardConfig {
    name: string;
    description: string;
    image: ITeamCardImage;
    textColor: string;
    backgroundColor: string;
}

export interface ITeamCardImage {
    src: string;
    alt: string;
}

export const TeamCard = ({ teamCardConfig }: { teamCardConfig: ITeamCardConfig }) => {
    const theme = useTheme() as any;
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));

    const { name, description, image, textColor, backgroundColor } = teamCardConfig;
    return (
        <Grid
            item
            container
            style={{
                width: smDown ? "100%" : "800px",
                position: "relative",
            }}
            alignItems={"center"}
            direction="row"
        >
            <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="center"
                style={{ position: "absolute", left: 0, width: smDown ? "100px" : "300px" }}
            >
                <Grid item>
                    <LinkedInContactIcon url={image?.src} iconContainerStyle={{ width: smDown ? "100px" : "250px" }} />
                </Grid>
            </Grid>
            <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                style={{
                    paddingLeft: smDown ? 0 : theme.spacing(5),
                    marginLeft: smDown ? "50px" : "120px",
                    padding: "1.9em 3em",
                    background: backgroundColor,
                    color: "#fff",
                    borderRadius: "4em",
                    transition: "color .2s ease,box-shadow .2s ease",
                }}
            >
                <TeamMemberName name={name} textColor={textColor} />
                <TeamMemberDescription description={description} textColor={textColor} />
            </Grid>
        </Grid>
    );
};

const TeamMemberDescription = ({ description, textColor }: { description: string, textColor: string }) => {
    const theme = useTheme() as any;

    return <Grid item>
        <Typography variant="body2" align="left" color={textColor} style={{ fontWeight: "bold", paddingLeft: theme.spacing(5) }}>
            {description}
        </Typography>
    </Grid>

}

const TeamMemberName = ({ name, textColor }: { name: string, textColor: string }) => {
    const theme = useTheme() as any;
    return <Grid item>
        <Typography
            variant="body1"
            align="right"
            color={textColor}
            style={{ fontWeight: "bold", paddingLeft: theme.spacing(8) }}
        >
            {name}
        </Typography>
    </Grid>
}