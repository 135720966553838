import { configureStore } from '@reduxjs/toolkit';
import languageReducer from './features/language/languageSlice';
import contactUsReducer from './features/contact-us/contactUsSlice';

export interface IState {
    language: {
        value: string;
    };
    contactUs: {
        showForm: boolean;
    }
}

export const store = configureStore({
    reducer: {
        language: languageReducer,
        contactUs: contactUsReducer,
    }
})