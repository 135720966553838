import * as React from 'react';
import { Grid } from '@mui/material';
import "./loader.css";

export const Loader = () => {
  return (
    <Grid container justifyContent={"center"} alignItems={"center"} style={{ height: "100vh" }}>
      <Grid item>
        <img className="image" src="/footer/logo_AE_noir.png" alt="" width="50" height="auto" />
      </Grid>
    </Grid>
  );
}