import { Grid, Typography } from "@mui/material";
import React from "react";
import CallToAction from "../calls-to-action/components/CallToAction";
import { useOpenPage } from "../../../../services/tools";

export interface ILogoConfig {
    src: string;
    height: string;
    alt: string;
}

export const WhoWeAre = ({
    logoConfig,
    title,
    subtitle,
    actionText,
    containerStylesOverride,
}: {
    logoConfig: ILogoConfig;
    title: string;
    subtitle: string;
    actionText: string;
    containerStylesOverride?: Object;
}) => {
    const { src, height, alt } = logoConfig;
    const openPage = useOpenPage();
    return (
        <Grid
            id="statement"
            item
            container
            style={{
                background:
                    "linear-gradient(180deg,#e9e9e9 30%,#e9e9e9 50%,#fac505 70%,#fac505)",
                height: "100vh",
                ...containerStylesOverride,
            }}
        >
            <Grid item container direction="column" spacing={8} style={{ padding: "10%" }}>
                <Grid item container justifyContent={"left"}>
                    <Grid item>
                        <img src={src} height={height} alt={alt} />
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h2"
                        component="h1"
                        color="text.primary"
                        style={{ fontSize: "bold" }}
                        align="left"
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1" color="primary.main" align="left">
                        {subtitle}
                    </Typography>
                </Grid>
                <Grid item container justifyContent="right" alignItems="center">
                    <CallToAction text={actionText} onClick={() => openPage('/about')} />
                </Grid>
            </Grid>
        </Grid>
    );
};
