import App from '../../../App'
import jump from 'jump.js'
const Scroll = {
    init: _ => { },
    easeInOutExpo: (t, b, c, d) => {
        if (t === 0) return b;
        if (t === d) return b + c;
        if ((t /= d / 2) < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
        return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
    },
    to: (element, options = {
        offset: 0,
        duration: 800
    }) => {
        if (!App.isScrolling) {
            App.isScrolling = true;
            if (typeof element === 'string') element = document.querySelector(element)
            if (!element) return
            jump(element, {
                // offset: options.offset || -App.header.offsetHeight,
                offset: options.offset,
                duration: options.duration,
                easing: Scroll.easeInOutExpo,
                callback: options.callback ? _ => {
                    App.isScrolling = false;
                    options.callback();
                } : _ => App.isScrolling = false
            });
        }
    },
    top: _ => {
        Scroll.to(document.body)
    }
};
export default Scroll;
