import React from "react";
import { Grid, IconButton } from "@mui/material";

const LinkedInIcon = ({width, style} : {width?: string, style?: object}) => {
  return <img alt="linkedin icon" src="/about-page/linked-in-background-white.svg" width={width || "100rem"} style={style} />;
}

export const LinkedInContactIcon = ({ url, iconContainerStyle, iconWidth }: { url?: string, iconContainerStyle?: Object, iconWidth?: string }) => {
    return <Grid item style={iconContainerStyle}>
        <a href={url || "https://www.linkedin.com/company/augmented-energy"} target="_blank" rel="noreferrer">
            <IconButton color="primary">
                <LinkedInIcon width={iconWidth} />
            </IconButton>
        </a>
    </Grid>
}