import React from "react";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { TemporaryDrawer } from "./components/TemporaryDrawer";
import { IImage } from "../interfaces";

export interface INavbarMenuItemConfig {
    url?: string;
    text?: string;
    onClick?: () => void;
    show?: () => boolean;
    style?: Object;
}

const StyledChip = styled(Chip)(({ theme }) => ({
    fontSize: "1rem",
    padding: "1.5rem 0.5rem",
    borderRadius: "5rem",
    "&:hover": {
        color: theme.palette.secondary.main,
        boxShadow: `0 0 0.8em 0 ${theme.palette.secondary.main}`,
    },
    "&.selected": {
        color: theme.palette.secondary.main,
    },
    fontWeight: "bold",
}));

const NavbarMenuItem = ({ text, href, onClick }: { text: string; href: string, onClick: any }) => {
    const location = useLocation();
    const isMenuItemSelected = location.pathname === href;
    if (onClick) {
        return <StyledChip color="primary" label={text} onClick={onClick} className={isMenuItemSelected ? "selected" : ""} />
    } else if (href.startsWith('https://') || href.startsWith('http://')) {
        return <a key={href} href={href} target="_blank" rel="noreferrer" style={{ textDecorationLine: "none" }}>
            <StyledChip color="primary" label={text} className={isMenuItemSelected ? "selected" : ""} />
        </a>
    } else {
        return <Link key={href} to={href} style={{ textDecorationLine: "none" }}>
            <StyledChip color="primary" label={text} className={isMenuItemSelected ? "selected" : ""} />
        </Link >
    }
};

const theme = createTheme({
    palette: {
        primary: {
            main: "#000000",
            // light: will be calculated from palette.primary.main,
            // dark: will be calculated from palette.primary.main,
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#FAC505",
            // dark: will be calculated from palette.secondary.main,
            contrastText: "#000000",
        },
    },
});

export const Navbar = ({ menuItemsConfig, logoImageConfig, overrideTheme }: { menuItemsConfig: INavbarMenuItemConfig[], logoImageConfig?: IImage, overrideTheme?: any }) => {
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Grid
            item
            style={{
                position: "fixed",
                backgroundColor: "transparent",
                width: "100%",
                zIndex: 2,
            }}
        >
            <ThemeProvider theme={overrideTheme || theme}>
                <Grid container>
                    <Grid item container justifyContent="flex-start" alignItems="flex-start" xs={1} style={{ padding: "10px" }}>
                        <Grid item>
                            <img src={logoImageConfig?.url || "/footer/logo_AE_noir.png"} height="30px" alt={logoImageConfig?.title || "Augmented Energy logo"} style={logoImageConfig?.style ? logoImageConfig?.style : {}} />
                        </Grid>
                    </Grid>
                    {smDown ? (
                        <Grid item container justifyContent="flex-end" alignItems="center" xs={11}>
                            <Grid item>
                                <TemporaryDrawer menuItemsConfig={menuItemsConfig} overrideTheme={theme} />
                            </Grid>
                        </Grid>
                    ) : (
                        <NavbarItemsDesktop menuItemsConfig={menuItemsConfig} />
                    )}
                </Grid>
            </ThemeProvider>
        </Grid>
    );
};

const NavbarItemsDesktop = ({ menuItemsConfig }: { menuItemsConfig: INavbarMenuItemConfig[] }) => {
    return <Grid
        xs={11}
        style={{
            padding: theme.spacing(2),
        }}
        item
        container
        spacing={2}
        justifyContent="flex-end"
    >
        {menuItemsConfig.map((itemConfig: any) => {
            return (
                <Grid item>
                    {(!itemConfig['show'] || itemConfig?.show()) && <NavbarMenuItem text={itemConfig.text} href={itemConfig.url} onClick={itemConfig.onClick} />}
                </Grid>
            );
        })}
    </Grid>

}