import { createTheme, responsiveFontSizes } from "@mui/material";
let theme = createTheme({
    palette: {
        primary: {
            main: "#151515",
            contrastText: "#FAC505",
        },
        secondary: {
            main: "#E9E9E9",
        },
        background: {
            paper: "#FFFFFF",
            default: "#E9E9E9",
        },
        text: {
            primary: "#151515",
            disabled: "#C7C7C7",
        },
        error: {
            main: "#721C24",
        },
    },
    shape: {
        borderRadius: 8,
    },
    typography: {
        fontFamily: '"Roboto", sans-serif',
        h1: {
            fontWeight: 700,
            fontSize: "4rem",
        },
        h2: {
            fontWeight: 600,
            fontSize: "1.8rem",
        },
        h3: {
            fontWeight: 600,
            fontSize: "1rem",
        },
        body1: {
            fontWeight: 400,
            fontSize: "1.7rem",
        },
    },
});
theme = responsiveFontSizes(theme);

export { theme };